import { render, staticRenderFns } from "./globalAnalysis.vue?vue&type=template&id=6393e4df&scoped=true&"
import script from "./globalAnalysis.vue?vue&type=script&lang=js&"
export * from "./globalAnalysis.vue?vue&type=script&lang=js&"
import style0 from "./globalAnalysis.vue?vue&type=style&index=0&id=6393e4df&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6393e4df",
  null
  
)

export default component.exports