<template>
<div class="global-analysis">
  <div class="cont display_flex-start">
    <div class="paper-info" >
      <div class="tit">试卷标题：{{ info.train && info.train.name }}</div>
      <p>考试时间: <span>{{ info.train && info.train.timelong }}</span>分钟</p>
      <p>试卷总分: <span>{{ info.train && info.train.total_score }}</span>分</p>
      <p>及格分数: <span>{{ info.train && info.train.pass_score }}</span>分</p>
    </div>
    <div class="type">
      <div class="tit">当前题型分布:</div>
      <div class="type-info">
        <div v-for="item in info.dataTypeList" :key="item.id">
          <p class="type-num">{{ item.data_count }}</p>
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
<!--    <div class="nodes">-->
<!--      <div class="tit">涵盖知识点:</div>-->
<!--      <div class="nodes-cont display_flex-start">-->
<!--        <p v-for="item in info.keypointList" :key="item.label_id">{{ item.label_name }}</p>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  <div class="cont mt10">
<!--    <div class="group-list">-->
<!--      <div class="active">-->
<!--        <p class="name">全部</p>-->
<!--        <p class="arrow"></p>-->
<!--      </div>-->
<!--      <div>-->
<!--        <p class="name">基础1班</p>-->
<!--        <p class="arrow"></p>-->
<!--      </div>-->
<!--      <div>-->
<!--        <p class="name">网络3班</p>-->
<!--        <p class="arrow"></p>-->
<!--      </div>-->
<!--    </div>-->
    <div class="group-info">
      <div class="group-info-item" style="flex: 2">
        <div>
          <p class="val">{{ info.isPassRate }}</p>
          <p>及格率</p>
        </div>
        <div>
          <p class="val">{{ info.useTimeAverage }}</p>
          <p>平均答题时长</p>
        </div>
      </div>
      <div class="group-info-item">
        <div>
          <p class="val">{{ info.userTotalCount }}</p>
          <p>应考人数</p>
        </div>
        <div>
          <p class="val">{{ info.userJoinCount }}</p>
          <p>实考人数</p>
        </div>
        <div>
          <p class="val">{{ info.userJoinRate }}</p>
          <p>参考率</p>
        </div>
      </div>
      <div class="group-info-item">
        <div>
          <p class="val">{{ info.averageScore }}</p>
          <p>平均分</p>
        </div>
        <div>
          <p class="val">{{ info.maxScore }}</p>
          <p>最高分</p>
        </div>
        <div>
          <p class="val">{{ info.minScore }}</p>
          <p>最低分</p>
        </div>
      </div>
    </div>
    <div class="mt20 absorb">
      <div class="absorb-l" v-if="info.mapTree && info.mapTree.length">
        <div class="tit">
          涵盖教材章节正确率:
        </div>
        <div class="absorb-l-cont">
          <div class="absorb-l-cont-item" v-for="item in info.mapTree" :key="item.id">
            <div class="absorb-l-cont-item-tit">{{ item.node.name }}</div>
            <div class="absorb-l-cont-item-list">
              <div class="absorb-l-cont-item-list-item" v-for="sItem in item.children" :key="sItem.id">
                <div class="absorb-l-cont-item-list-item-name">{{ sItem.node.name }}</div>
                <div class="absorb-l-cont-item-list-item-progress">
                  <div class="progress-bj"><div class="progress-val" :style="{width:(sItem.data_count !=0 ? (sItem.right_data_count/sItem.data_count*100) : 0) + '%'}"></div></div>
                  <div class="absorb-l-cont-item-list-item-progress-nr">共{{sItem.data_count}}题，正确率：{{ sItem.data_count !=0 ? (sItem.right_data_count/sItem.data_count*100).toFixed(2) : 0 }}%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="absorb-r">
        <div class="tit">
          知识点掌握分析:
        </div>
        <div class="absorb-r-cont">
          <div class="absorb-r-cont-item" v-for="item in info.keypointList" :key="item.label_id">
            <div class="absorb-r-cont-item-name">{{ item.label_name }}</div>
            <div class="absorb-r-cont-item-progress">
              <div class="progress-bj"><div class="progress-val" :style="{width:(item.total != '0' ? (item.right_count / item.total * 100) : 0) + '%'}"></div></div>
              <div class="absorb-r-cont-item-progress-nr">掌握程度：{{ item.total != '0' ? (item.right_count / item.total*100).toFixed(2) : 0 }}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="cont mt10">
    <div class="score-top">
      <div class="tit">分数段统计 (单位：人)</div>
      <div class="person-color"><span></span>人数</div>
    </div>
    <div id="score-chart" style="width: 100%;height: 400px"></div>
  </div>
</div>
</template>

<script>
export default {
  name: "globalAnalysis",
  data(){
    return{
      info:{},
      trainId:''

    }
  },
  mounted(){
    this.trainId = this.$route.query.id
    this.getDate();
  },
  methods:{
    getDate(){
      let params={
        train_id:this.trainId
      }
      this.api.analysis.datasetTrainReport(params).then((res)=>{
        this.info = res;

        this.setTypeRateChart();
      })
    },
    setTypeRateChart(){
      let chartDom = document.getElementById('score-chart');
      let myChart = this.$echarts.init(chartDom);
      let option;

      let names = [];
      let counts = [];
      for(let name in this.info.scoreParts){
        names.push(name);
        counts.push(this.info.scoreParts[name]);
      }


      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          // data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine'],
          show:false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '0',
          top:'20px',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false,
          //data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: names
        },
        yAxis: {
          type: 'value'
        },
        series: [
          // {
          //   name: 'Email',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [120, 132, 101, 134, 90, 230, 210]
          // },
          {
            name: '人数',
            type: 'bar',
            stack: 'Total',
            data: counts,
            barMaxWidth:'60'
          },
        ]
      };

      option && myChart.setOption(option);

      window.addEventListener('resize', () => {
        if (myChart) {
          myChart.resize()
        }
      })

    },
  }
}
</script>

<style scoped lang="scss">
.global-analysis{
  padding-bottom: 20px;
  .cont{
    padding: 20px;
    background-color: #FFFFFF;
    font-size: 14px;
    color: #333333;

    .paper-info,
    .nodes{
      padding: 20px;
      height: 172px;
      background: #F6F7FA;
      border-radius: 6px;
      flex: 3;
      margin-right: 20px;
    }
    .paper-info{
      >p{
        margin-bottom: 12px;
        >span{
          padding: 0 5px;
          color:#4A79FF;
          font-weight: bold;
        }
      }
    }
    .nodes{
      border-left: 3px solid #36CBCB;
      .nodes-cont{
        flex-wrap: wrap;
        height: 100px;
        overflow-y: auto;
        >p{
          margin-right: 10px;
          margin-bottom: 10px;
          padding: 0 10px;
          height: 28px;
          background: #FFFFFF;
          border-radius: 4px;
          color: #666666;
          line-height: 28px;
        }
      }
    }
    .tit{
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .type{
      padding: 20px;
      height: 172px;
      background: #F6F7FA;
      border-radius: 6px;
      flex: 2;
      //margin-right: 20px;
      border-left: 3px solid #FFCA6A;
      .type-info{
        margin-top: 30px;
        //padding: 0 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        >div{
          flex: 1;
          text-align: center;
          border-right: 1px solid #DDDDDD;
          .type-num{
            margin-bottom: 6px;
            font-size: 20px;
            font-weight: bold;
            color: #4A79FF;
          }
        }
        >div:last-child{
          border-right:none;
        }
      }
    }
    .group-list{
      display: flex;
      justify-content: flex-start;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      >div{
        margin-right: 10px;
        .name{
          width: 100px;
          height: 42px;
          background: #F6F7FA;
          border-radius: 6px;
          text-align: center;
          line-height: 42px;
          cursor: pointer;
        }
      }
      .active{
        .name{
          background: #4A79FF;
          color:#FFFFFF;
        }

        .arrow{
          margin: 0 auto;
          width: 0px;
          height: 0px;
          border-top: 10px solid #4A79FF;
          /*其它3边设置相同颜色，*/
          border-bottom: 10px solid white;
          border-left: 10px solid white;
          border-right: 10px solid white;
        }
      }
    }
    .group-info{
      display: flex;
      justify-content: space-between;
      .group-info-item{
        margin-right: 26px;
        flex: 3;
        height: 98px;
        background: #F6F7FA;
        border-radius: 6px;
        font-size: 14px;
        color: #666666;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        >div{
          flex: 1;
          .val{
            margin-bottom: 6px;
            font-size: 20px;
          }
        }
      }
      .group-info-item:last-child{
        margin-right: 0;
      }
    }
    .absorb{
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;

      .absorb-l{
        flex: 1;
        margin-right: 20px;
        .absorb-l-cont{
          padding: 20px;
          height: 315px;
          background: #F6F7FA;
          border-radius: 6px;
          overflow-y: auto;
          .absorb-l-cont-item{
            .absorb-l-cont-item-tit{
              font-weight: bold;
            }
            .absorb-l-cont-item-list{
              padding-left: 50px;
              margin-bottom: 20px;
              .absorb-l-cont-item-list-item{
                margin-top: 20px;
                .absorb-l-cont-item-list-item-progress{
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  .absorb-l-cont-item-list-item-progress-nr{
                    margin-left: 16px;
                    width: 240px;
                  }
                }
              }
            }
          }
        }
      }
      .absorb-r{
        flex: 1;
        .absorb-r-cont{
          padding: 20px;
          height: 315px;
          background: #F6F7FA;
          border-radius: 6px;
          overflow-y: auto;

          .absorb-r-cont-item{
            margin-bottom: 16px;
            .absorb-r-cont-item-name{
              margin-bottom: 5px;
            }
            .absorb-r-cont-item-progress{
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .absorb-r-cont-item-progress-nr{
                margin-left: 16px;
                width: 210px;
              }
            }
          }
        }
      }
    }
    .score-top{
      display: flex;
      justify-content: space-between;
      align-items: center;

      .person-color{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >span{
          margin-right: 5px;
          display: inline-block;
          width: 14px;
          height: 14px;
          background: #4A79FF;
          border-radius: 1px;
        }
      }
    }
  }
  .progress-bj{
    flex: 1;
    height: 10px;
    background: #EEEEEE;
    border-radius: 5px;
    position: relative;
    .progress-val{
      width: 70%;
      height: 10px;
      background: #4A79FF;
      border-radius: 5px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .display_flex-start{
    display: flex;
    justify-content: flex-start;

  }
  .mt10{
    margin-top: 10px;
  }
  .mt20{
    margin-top: 20px;
  }
}
</style>
